<template>
  <div class="star_page">
    <div class="star_page_tip">
      <p>*以下为各平台假冒售卖羽素产品页面截图</p>
      <p>
        羽素官方对于侵权(<span class="red">假冒伪劣、盗图、不正规售卖等</span
        >)行为，将严惩必究
      </p>
    </div>
    <ul class="star_page_item">
      <li v-for="(item, index) in imgList" :key="index">
        <img :src="item" alt="" @click="bigImg(index)" />
      </li>
    </ul>
    <div class="mask_block" v-if="isBlock">
      <img :src="imgUrl" alt="" />
      <div class="close" @click="handClose">x</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import img101 from "../assets/images/star/101.jpg";
import img102 from "../assets/images/star/102.jpg";
import img107 from "../assets/images/star/107.jpg";
import img108 from "../assets/images/star/108.png";
import img109 from "../assets/images/star/109.png";
import img110 from "../assets/images/star/110.png";
import img112 from "../assets/images/star/112.jpg";
import img113 from "../assets/images/star/113.png";
import img125 from "../assets/images/star/125.jpg";
import img127 from "../assets/images/star/127.jpg";
import img128 from "../assets/images/star/128.jpg";
import img129 from "../assets/images/star/129.jpg";
import img130 from "../assets/images/star/130.png";
import img133 from "../assets/images/star/133.png";
import img136 from "../assets/images/star/136.png";
import img137 from "../assets/images/star/137.png";
import img138 from "../assets/images/star/138.png";
import img139 from "../assets/images/star/139.png";
import img140 from "../assets/images/star/140.png";
import img142 from "../assets/images/star/142.png";
import img143 from "../assets/images/star/143.png";
import img144 from "../assets/images/star/144.png";
import img146 from "../assets/images/star/146.png";
import img147 from "../assets/images/star/147.png";
import img148 from "../assets/images/star/148.png";
import img149 from "../assets/images/star/149.png";
import img150 from "../assets/images/star/150.png";
import img151 from "../assets/images/star/151.png";
import img152 from "../assets/images/star/152.png";
import img153 from "../assets/images/star/153.png";
import img159 from "../assets/images/star/159.jpg";
import img163 from "../assets/images/star/163.png";
import img164 from "../assets/images/star/164.png";
import img165 from "../assets/images/star/165.png";
import img166 from "../assets/images/star/166.png";
import img167 from "../assets/images/star/167.png";
import img168 from "../assets/images/star/168.png";
import img169 from "../assets/images/star/169.jpg";
import img170 from "../assets/images/star/170.jpg";
import img171 from "../assets/images/star/171.png";
import img172 from "../assets/images/star/172.png";
import img173 from "../assets/images/star/173.png";
import img174 from "../assets/images/star/174.png";
import img175 from "../assets/images/star/175.png";
import img176 from "../assets/images/star/176.png";
import img177 from "../assets/images/star/177.jpg";
import img178 from "../assets/images/star/178.jpg";
import img179 from "../assets/images/star/179.png";
import img180 from "../assets/images/star/180.png";
import img181 from "../assets/images/star/181.jpg";
import img182 from "../assets/images/star/182.jpg";
import img183 from "../assets/images/star/183.jpg";
import img184 from "../assets/images/star/184.jpg";
import img185 from "../assets/images/star/185.jpg";
import img186 from "../assets/images/star/186.png";
import img187 from "../assets/images/star/187.jpg";
import img188 from "../assets/images/star/188.jpg";
import img189 from "../assets/images/star/189.jpg";
import img190 from "../assets/images/star/190.jpg";
import img191 from "../assets/images/star/191.jpg";
import img192 from "../assets/images/star/192.jpg";
import img193 from "../assets/images/star/193.jpg";
import img194 from "../assets/images/star/194.jpg";
import img195 from "../assets/images/star/195.jpg";
import img196 from "../assets/images/star/196.jpg";
import img197 from "../assets/images/star/197.jpg";
import img198 from "../assets/images/star/198.jpg";
import img199 from "../assets/images/star/199.jpg";
import img200 from "../assets/images/star/200.png";
import img201 from "../assets/images/star/201.png";
import img202 from "../assets/images/star/202.jpg";
import img203 from "../assets/images/star/203.jpg";
import img204 from "../assets/images/star/204.jpg";
import img205 from "../assets/images/star/205.jpg";
import img206 from "../assets/images/star/206.jpg";
import img207 from "../assets/images/star/207.png";
import img208 from "../assets/images/star/208.png";
import img209 from "../assets/images/star/209.png";
import img210 from "../assets/images/star/210.jpg";
import img211 from "../assets/images/star/211.jpg";
import img212 from "../assets/images/star/212.jpg";
import img213 from "../assets/images/star/213.jpg";
import img214 from "../assets/images/star/214.jpg";
import img215 from "../assets/images/star/215.jpg";
import img216 from "../assets/images/star/216.jpg";
import img217 from "../assets/images/star/217.jpg";
import img218 from "../assets/images/star/218.jpg";
import img219 from "../assets/images/star/219.png";
import img220 from "../assets/images/star/220.jpg";
import img221 from "../assets/images/star/221.jpg";
import img222 from "../assets/images/star/222.jpg";
import img223 from "../assets/images/star/223.jpg";
import img224 from "../assets/images/star/224.jpg";
import img225 from "../assets/images/star/225.jpg";
import img226 from "../assets/images/star/226.jpg";
import img227 from "../assets/images/star/227.jpg";
import img228 from "../assets/images/star/228.jpg";
import img229 from "../assets/images/star/229.jpg";
import img230 from "../assets/images/star/230.jpg";
import img231 from "../assets/images/star/231.jpg";
import img232 from "../assets/images/star/232.jpg";
import img233 from "../assets/images/star/233.jpg";
import img234 from "../assets/images/star/234.jpg";
import img235 from "../assets/images/star/235.jpg";
import img236 from "../assets/images/star/236.jpg";
import img237 from "../assets/images/star/237.jpg";
import img238 from "../assets/images/star/238.jpg";
import img239 from "../assets/images/star/239.jpg";
import img240 from "../assets/images/star/240.jpg";
import img241 from "../assets/images/star/241.jpg";
import img242 from "../assets/images/star/242.jpg";
import img243 from "../assets/images/star/243.jpg";
import img244 from "../assets/images/star/244.jpg";
import img245 from "../assets/images/star/245.jpg";
import img246 from "../assets/images/star/246.jpg";
import img247 from "../assets/images/star/247.jpg";
import img248 from "../assets/images/star/248.jpg";
import img249 from "../assets/images/star/249.jpg";
import img250 from "../assets/images/star/250.jpg";
import img251 from "../assets/images/star/251.jpg";
import img252 from "../assets/images/star/252.jpg";
import img253 from "../assets/images/star/253.jpg";
import img254 from "../assets/images/star/254.jpg";
import img255 from "../assets/images/star/255.jpg";
import img256 from "../assets/images/star/256.jpg";
import img257 from "../assets/images/star/257.jpg";
import img258 from "../assets/images/star/258.jpg";
import img259 from "../assets/images/star/259.jpg";
import img260 from "../assets/images/star/260.jpg";
import img261 from "../assets/images/star/261.jpg";
import img262 from "../assets/images/star/262.jpg";
import img263 from "../assets/images/star/263.jpg";
import img264 from "../assets/images/star/264.jpg";
import img265 from "../assets/images/star/265.jpg";
import img266 from "../assets/images/star/266.jpg";
import img267 from "../assets/images/star/267.jpg";
import img268 from "../assets/images/star/268.jpg";
import img269 from "../assets/images/star/269.jpg";
import img270 from "../assets/images/star/270.jpg";
import img271 from "../assets/images/star/271.jpg";
import img272 from "../assets/images/star/272.jpg";
import img273 from "../assets/images/star/273.jpg";
import img274 from "../assets/images/star/274.jpg";
import img275 from "../assets/images/star/275.jpg";
import img276 from "../assets/images/star/276.jpg";
import img277 from "../assets/images/star/277.jpg";
import img278 from "../assets/images/star/278.jpg";
import img279 from "../assets/images/star/279.jpg";
import img280 from "../assets/images/star/280.jpg";
import img281 from "../assets/images/star/281.jpg";
import img282 from "../assets/images/star/282.jpg";
import img283 from "../assets/images/star/283.jpg";
import img284 from "../assets/images/star/284.jpg";
import img285 from "../assets/images/star/285.jpg";
import img286 from "../assets/images/star/286.jpg";
import img287 from "../assets/images/star/287.jpg";
import img288 from "../assets/images/star/288.jpg";
import img289 from "../assets/images/star/289.jpg";
import img290 from "../assets/images/star/290.jpg";
import img291 from "../assets/images/star/291.jpg";
import img292 from "../assets/images/star/292.jpg";
import img293 from "../assets/images/star/293.jpg";
import img294 from "../assets/images/star/294.jpg";
import img295 from "../assets/images/star/295.jpg";
import img296 from "../assets/images/star/296.jpg";
import img297 from "../assets/images/star/297.jpg";
import img298 from "../assets/images/star/298.jpg";
import img299 from "../assets/images/star/299.jpg";
import img300 from "../assets/images/star/300.jpg";
import img301 from "../assets/images/star/301.jpg";
import img302 from "../assets/images/star/302.jpg";
import img303 from "../assets/images/star/303.jpg";
import img304 from "../assets/images/star/304.jpg";
import img305 from "../assets/images/star/305.jpg";
import img306 from "../assets/images/star/306.jpg";
import img307 from "../assets/images/star/307.jpg";
import img308 from "../assets/images/star/308.jpg";
import img309 from "../assets/images/star/309.jpg";
import img310 from "../assets/images/star/310.jpg";
import img311 from "../assets/images/star/311.jpg";
import img312 from "../assets/images/star/312.jpg";
import img313 from "../assets/images/star/313.jpg";
import img314 from "../assets/images/star/314.jpg";
import img315 from "../assets/images/star/315.jpg";
import img316 from "../assets/images/star/316.jpg";
import img317 from "../assets/images/star/317.jpg";
import img318 from "../assets/images/star/318.jpg";

export default {
  name: "talent",

  data: () => {
    return {
      isBlock: false,
      imgUrl: null,
      imgList: [
        img101,
        img102,
        img107,
        img108,
        img109,
        img110,
        img112,
        img113,
        img125,
        img127,
        img128,
        img129,
        img130,
        img133,
        img136,
        img137,
        img138,
        img139,
        img140,
        img142,
        img143,
        img144,
        img146,
        img147,
        img148,
        img149,
        img150,
        img151,
        img152,
        img153,
        img159,
        img163,
        img164,
        img165,
        img166,
        img167,
        img168,
        img169,
        img170,
        img171,
        img172,
        img173,
        img174,
        img175,
        img176,
        img177,
        img178,
        img179,
        img180,
        img181,
        img182,
        img183,
        img184,
        img185,
        img186,
        img187,
        img188,
        img189,
        img190,
        img191,
        img192,
        img193,
        img194,
        img195,
        img196,
        img197,
        img198,
        img199,
        img200,
        img201,
        img202,
        img203,
        img204,
        img205,
        img206,
        img207,
        img208,
        img209,
        img210,
        img211,
        img212,
        img213,
        img214,
        img215,
        img216,
        img217,
        img218,
        img219,
        img220,
        img221,
        img222,
        img223,
        img224,
        img225,
        img226,
        img227,
        img228,
        img229,
        img230,
        img231,
        img232,
        img233,
        img234,
        img235,
        img236,
        img237,
        img238,
        img239,
        img240,
        img241,
        img242,
        img243,
        img244,
        img245,
        img246,
        img247,
        img248,
        img249,
        img250,
        img251,
        img252,
        img253,
        img254,
        img255,
        img256,
        img257,
        img258,
        img259,
        img260,
        img261,
        img262,
        img263,
        img264,
        img265,
        img266,
        img267,
        img268,
        img269,
        img270,
        img271,
        img272,
        img273,
        img274,
        img275,
        img276,
        img277,
        img278,
        img279,
        img280,
        img281,
        img282,
        img283,
        img284,
        img285,
        img286,
        img287,
        img288,
        img289,
        img290,
        img291,
        img292,
        img293,
        img294,
        img295,
        img296,
        img297,
        img298,
        img299,
        img300,
        img301,
        img302,
        img303,
        img304,
        img305,
        img306,
        img307,
        img308,
        img309,
        img310,
        img311,
        img312,
        img313,
        img314,
        img315,
        img316,
        img317,
        img318,
      ],
    };
  },
  methods: {
    bigImg(index) {
      this.imgUrl = this.imgList[index];
      this.isBlock = true;
    },
    handClose() {
      this.isBlock = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.star_page {
  text-align: center;
  .star_page_tip {
    font-size: 0.13rem;
    margin: 5% 0;
    p {
      color: #3e3a39;
      line-height: 40px;
    }
  }
  .red {
    color: #e60012;
  }
  .star_page_item {
    width: 92%;
    padding: 0 4%;
    display: flex;
    justify-content: start;
    flex-flow: wrap;
    li {
      width: 30%;
      list-style: none;
      background: #f7f8f8;
      box-shadow: 5px 5px 2px #e3e2e2;
      padding: 50px;
      box-sizing: border-box;
      margin-bottom: 2%;
      margin-right: 5%;
      &:nth-child(3n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        display: block;
        margin: 0 auto;
        border: 2px solid #f6e344;
        cursor: pointer;
      }
    }
  }
  .mask_block {
    position: fixed;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.7);
    z-index: 10001;
    img {
      height: 80%;
      display: block;
      margin: 5% auto 0;
    }
    .close {
      border: 5px solid #ccc;
      width: 40px;
      height: 40px;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
      font-size: 40px;
      font-weight: 100;
      position: fixed;
      top: 5%;
      right: 20%;
      color: #ccc;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1500px) {
    .close {
      right: 10.5% !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .mask_block {
      img {
        height: 60% !important;
        margin: 15% auto 0;
      }
    }
  }
}
</style>
